<template>
  <div>
    <div class="detail-wrapper">
      <el-row class="message">
        <el-col :span="20"><span>店铺: </span>{{shopData.shopName}}</el-col>
      </el-row>
      <el-row class="message">
        <el-col :span="8"><span>提交人: </span>{{shopData.commitUser}}</el-col>
        <el-col :span="8" :offset="6"><span>提交日期: </span> {{shopData.submitTime}}</el-col>
      </el-row>
      <el-row class="message" v-if="shopData.saleTime">
        <el-col :span="8">
          <span>销售日期:</span>
          {{shopData.saleTime}}
          {{ shopData.period || '' }}
        </el-col>
        <el-col :span="8" :offset="6">
          <span>销售状态:</span>
          <span>{{ shopData.typeText }}</span>
        </el-col>
      </el-row>
      <el-row class="message">
        <el-col :span="20"><span>支付信息: </span>  <d-lable style="width:80%" :tableData='tableData' :columnData='columnData'></d-lable></el-col>
      </el-row>
      <el-row class="message" v-if="goodsTableData.length">
        <el-col :span="20"><span>货品信息: </span>
          <d-lable style="width:80%"
            :tableData='goodsTableData'
            :columnData='goodsColumnData'></d-lable>
        </el-col>
      </el-row>
      <el-row class="message">
        <el-col :span="20"><span>照片凭证: </span>
          <el-image v-for="(item,index) in shopData.photoProve" :key="index"  class="photo" :src="item" :preview-src-list="[item]"></el-image>
        </el-col>
      </el-row>
      <el-row class="message">
        <el-col :span="20"><span>状态: </span>{{shopData.status}}</el-col>
      </el-row>
      <el-row class="message">
        <el-col :span="20"><span>说明: </span>{{shopData.instructions}}</el-col>
      </el-row>
    </div>
    <div class="rowBtn">
      <el-button @click="cancel" size="medium">取消</el-button>
    </div>
  </div>
</template>

<script>
import DLable from '../../../components/d-table'
import { mapState } from 'vuex'
export default {
  data(){ 
    return {
      srcList:[],
      shopData:{},
        columnData:[
        {prop:'type',label:'支付方式'},
        {prop:'number',label:'支付笔数'},
        {prop:'money',label:'支付金额'},
      ],
      tableData:[],
      goodsColumnData: [
        { prop: 'goodsName', label: '货品名称' },
        { prop: 'totalPrice', label: '货品金额' },
      ],
      goodsTableData: [],
    }
  },
  computed: {

  },
  components: { 
    DLable
  },
  created () {
    this.getReportSaleInfo( )
  },
  mounted () {
  },
  methods: {
    /**
     * 获取详情
     */
    getReportSaleInfo(){
      this.$api.checkapi.getReportSaleInfo(this.$route.query.id)
        .then(({code , data , msg})=>{
          if(code === 200){
            this.shopData = {
              ...data,
              status:['已驳回','通过','待审核','撤销'][data.status],
              photoProve: data.photoProve ? data.photoProve.split(',') : null
            }
            this.tableData = this.shopData.payInfos
            if (Array.isArray(this.shopData.goods)) {
              this.goodsTableData = this.shopData.goods
              let totalPrice = this.shopData.goods.map(item => Number(item.totalPrice)).reduce((a, b) => {
                return Number(a) + Number(b);
              }, 0)
              let totalData = {
                goodsName: '合计',
                totalPrice
              }
              this.goodsTableData.push(totalData)
            }
          }else{
            this.$message.error(msg);
          }
        })
    },
    /**
     * 取消
     */
    cancel(){
      this.$router.go(-1)
    }
  },
  watch: {
  }
}
</script>
<style lang="scss" scoped>
@import "src/pages/style/detail.scss";
</style>  
