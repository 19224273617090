<template>
  <div class="report">
<!--    <div class="title">-->
<!--      <div class="sort">-->
<!--        <div class="border-left">-->
<!--          <span>已通过</span>-->
<!--          <br />-->
<!--          <P>{{statusCount.pass || 0}}个</P>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="sort">-->
<!--        <div class="border-left">-->
<!--          <span>已驳回</span>-->
<!--          <br />-->
<!--          <P>{{statusCount.reject || 0}}个</P>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="sort">-->
<!--        <div class="border-left">-->
<!--          <span>本周完成数</span>-->
<!--          <br />-->
<!--          <P>{{statusCount.total || 0}}个</P>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div class="content">
      <d-search
        class="d-search"
        :searchData="searchData"
        @searchEvent="searchEvent"
        @openEvent="openEvent"
        @exportEvent="exportEvent"
      />

      <d-lable :columnData="columnData" :tableData="tableData" @viewEvent="viewEvent"></d-lable>
      <d-paging :pager="pager" @change="getItemList" />
    </div>
  </div>
</template>

<script>
import DSearch from "../../../components/d-search";
import DLable from "../../../components/d-table";
import DPaging from "../../../components/d-paging";
import { options } from "../../../components/options";
import { onDownload } from "@/utils/utils";
export default {
  data() {
    return {
      pager: {
        count: 0,
        page: 1,
        rows: 10
      },
      statusCount: {
        reject: null,
        pass: null,
        total: null
      },
      searchData: {
        searchItem: [
          {
            label: "状态",
            value: "status",
            type: "commonSelect",
            placeholder: "",
            options: []
          },
          {
            label: "选择店铺",
            value: "shopIdList",
            type: "linkageSelect",
            dateType: "month",
            placeholder: "",
            options: options,
            optionProps: {
              value: "name",
              label: "name",
              children: "childList"
            }
          }
        ],
        searchBtn: {
          queryBtn: true,
          exportBtn: true,
          isOpen: {
            type: true,
            open: true
          }
        },
        params: {
          status: null
        }
      },
      columnData: [
        { prop: "id", label: "编号" },
        { prop: "floor", label: "楼层" },
        { prop: "shopNo", label: "店铺号", width: "120" },
        { prop: "shopName", label: "店铺名称", width: "120" },
        { prop: "status", label: "状态" },
        { prop: "commitUser", label: "提交人" },
        { prop: "createTime", label: "提交时间", width: "200" },
        {
          prop: "operate",
          label: "操作",
          type: {
            viewBtn: true
          }
        }
      ],
      tableData: []
    };
  },
  computed: {},
  components: {
    DSearch,
    DLable,
    DPaging
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    /**
     * 列表查看btn
     */
    viewEvent(column) {
      this.$router.push({
        path: "/statement/certificateInfo",
        query: { id: column.id }
      });
    },
    /**
     * 搜索按钮点击
     */
    searchEvent() {
      this.getScanLogList();
    },
    /**
     * 展开收起按钮
     */
    openEvent(status) {
      this.searchData.searchBtn.isOpen.open = status;
      if (!this.searchData.searchBtn.isOpen.open) {
        const name = {
          label: "名字",
          value: "name",
          type: "commonInput",
          dateType: "month",
          placeholder: "请输入"
        };
        this.searchData.searchItem.push(name);
      } else {
        this.searchData.searchItem = this.searchData.searchItem.slice(0, 2);
      }
    },
    /**
     * 证件管理列表
     */
    getScanLogList(shop) {
      const obj = {
        pageNum: this.pager.page,
        pageSize: this.pager.rows,
        status: this.searchData.params.status,
        shop: this.searchData.params.shopIdList
          ? this.searchData.params.shopIdList[
              this.searchData.params.shopIdList.length - 1
            ]
          : null,
        name: this.searchData.params.name
      };
      this.$api.checkapi
        .getCardManagerList(shop || obj)
        .then(({ code, data, msg }) => {
          if (code === 200) {
            this.pager.count = data.total;
            this.tableData = data.list.map(item => ({
              ...item,
              sex: item.sex === "F" ? "女性" : item.sex === "M" ? "男性" : "无",
              status:
                item.status == 0
                  ? "驳回"
                  : item.status == 1
                  ? "通过"
                  : item.status == 2
                  ? "待审核"
                  : "错误状态"
            }));
          } else {
            this.$message.error(msg);
          }
        });
    },
    /**
     * 导出
     */
    exportEvent() {
      const obj = {
        status: this.searchData.params.status,
        shop: this.searchData.params.shopIdList
          ? this.searchData.params.shopIdList[
              this.searchData.params.shopIdList.length - 1
            ]
          : null,
        name: this.searchData.params.name
      };
      this.$api.mentapi.cardManagerExport(obj).then(res => {
        let { headers, data } = res;
        onDownload(headers, data);
      });
    },
    /**
     * 查询请求
     */
    getItemList() {
      this.getScanLogList();
    },
    /**
     * 获取选择店铺列表
     */
    getShopList() {
      this.$api.shopapi.getShopList().then(({ data, code }) => {
        if (code == 200) {
          this.childList = data.childList;
          const child = [
            {
              id: data.id,
              name: data.name,
              childList: data.childList
            }
          ];
          this.searchData.searchItem.forEach(item => {
            if (item.type === "linkageSelect") {
              item.options = child;
            }
          });
        }
      });
    },
    /**
     * 获取上报销售信息
     */
    getCardManagerInfo() {
      this.$api.mentapi.getCardManagerInfo().then(({ data, code }) => {
        if (code === 200) {
          this.statusCount = data;
        }
      });
    },
    /**
     * 获取状态列表
     */
    getStatusList() {
      this.$api.sysapi
        .getStatusList({ dictType: "report_status" })
        .then(({ code, data }) => {
          this.searchData.searchItem[0].options = data.list.map(item => ({
            value: item.dictValue,
            label: item.dictLabel
          }));
        });
    },
    /**
     * init 初始化
     */
    init() {
      this.getShopList();
      this.getStatusList();
      // this.getCardManagerInfo();
      this.getScanLogList();
    }
  },
  watch: {}
};
</script>
<style lang="scss" scoped>
.report {
  width: 100%;
  .title {
    width: 100%;
    height: 105px;
    background-color: #fff;
    display: flex;
    .sort:nth-child(-n + 2) {
      .border-left {
        border-right: 1px solid #ccc;
      }
    }
    .sort {
      width: 33.33%;
      height: 100%;
      text-align: center;
      .border-left {
        margin-top: 20px;
        span {
          display: inline-block;
          font-weight: 400;
          font-style: normal;
          color: #999999;
        }
        p {
          display: inline-block;
          font-weight: 400;
          font-style: normal;
          font-size: 24px;
          color: #1b1b1b;
          margin-top: 10px;
        }
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    padding: 0 30px;
    text-align: center;
    .d-search {
      padding: 30px 0;
    }
    .el-table {
      margin-bottom: 20px;
      flex: 1;
      overflow-y: auto;
    }
  }
}
</style>
