<template>
  <div>
    <div class="detail-wrapper">
      <el-row class="message">
        <el-col :span="20"><span>店铺: </span>{{shopData.shopName}}</el-col>
      </el-row>
      <el-row class="message">
        <el-col :span="8"><span>提交人: </span>{{shopData.commitUser}}</el-col>
        <el-col :span="8" :offset="6"><span>提交日期: </span>{{shopData.createTime}}</el-col>
      </el-row>
      <el-row class="message">
        <el-col :span="8"><span>出门日期: </span>{{shopData.outTime}}</el-col>
        <el-col :span="8" :offset="6"><span>出入有效期: </span>{{shopData.beginTime}} 至 {{shopData.endTime}}</el-col>
      </el-row>

      <el-row class="message">
        <el-col :span="20"><span>货物图片: <el-image v-for="(item,index) in shopData.itemPhoto" :key="index"  class="photo" :src="item" :preview-src-list="srcList" @click="previewSrc(item)"></el-image></span></el-col>
      </el-row>

    </div>
    <div class="rowBtn">
      <el-button @click="cancel"  size="medium">取消</el-button>
    </div>
  </div>
</template>

<script>
import DLable from '../../../components/d-table'
import { mapState } from 'vuex'
export default {
  data(){ 
    return {
      srcList:[],
      shopData:{}
    }
  },
  computed: {

  },
  components: {
    DLable
  },
  created () {
    this.accessPermissionInfo()
  },
  mounted () {
  },
  methods: {
    /**
     * 获取详情
     */
    accessPermissionInfo(){
      this.$api.checkapi.accessPermissionInfo(this.$route.query.id)
        .then(({code , data , msg})=>{
          if(code === 200){
            this.shopData = {
              ...data,
              itemPhoto:data.itemPhoto.split(',')
            }
          }else{
            this.$message.error(msg);
          }
        })
    },
    /**
     * 取消
     */
    cancel(){
      this.$router.go(-1)
    },
    /**
     * 驳回
     */
    failCheck(){
        this.$prompt('请输入驳回理由', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(({ value }) => {
          const obj = {
            id:this.$route.query.id,
            status : 0,
            rejectReason:value
          }
          this.$api.checkapi.accessPermissionUpdate(obj)
            .then(({code , data , msg})=>{
              if(code === 200){
                this.$router.go(-1)
              }else{
                this.$message.error(msg);
              }
            })
        }).catch(() => {
                
        });
    },
    /**
     * 通过
     */
    byOutAllowCheck(){
      const obj = {
        id:this.$route.query.id,
        status : 1
      }
      this.$api.checkapi.accessPermissionUpdate(obj)
        .then(({code , data , msg})=>{
          if(code === 200){
            this.$router.go(-1)
          }else{
            this.$message.error(msg);
          }
        })
    },
    /**
     * 查看大图
     */
    previewSrc(src){
      this.srcList = [src]
    },
  },
  watch: {
  }
}
</script>
<style lang="scss" scoped>
@import "src/pages/style/detail.scss";
</style>  
